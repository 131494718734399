import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';

import HomePage from './pages/HomePage';
import AspireProject from './pages/AspireProject.js';
import ConvertBankStatement from './pages/ConvertBankStatement.js';
import BrainFlix from './pages/BrainFlix.js';
import BandSite from './pages/BandSite.js';
import SarahAyling from './pages/SarahAyling.js';
import Shrink from './pages/Shrink.js';


import ScrollToAnchor from './components/ScrollToAnchor/ScrollToAnchor.js';

function App() {
  return (
    <BrowserRouter>
    <Navbar/>
    <ScrollToAnchor/>
    <Routes>
      <Route index element={<HomePage/>}/>
      <Route path="/home" element={<HomePage/>}/>
      <Route path="/AspireFitness" element={<AspireProject/>}/>
      <Route path="/ConvertBankStatement" element={<ConvertBankStatement/>}/>
      <Route path="/BrainFlix" element={<BrainFlix/>}/>
      <Route path="/BandSite" element={<BandSite/>}/>
      <Route path="/SarahAylingPhysiotherapy" element={<SarahAyling/>}/>
      <Route path="/Shrink.ai" element={<Shrink/>}/>
    </Routes>
    <Footer/>
    </BrowserRouter>
  );
}

export default App;
