import React, { useState } from "react";

// import { useHistory } from 'react-router-dom';

import headshot from "../../assets/png/headshot.jpg";
import Hamburger from "../../assets/svg/ham-menu.svg";
import HamburgerClose from "../../assets/svg/ham-menu-close.svg";

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const history = useHistory();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  // const navigateToHome = () => {
  //   history.push("/"); // Navigate to the home page using React Router's history.push
  // };

  return (
    <header class="header">
      <div class="header__content">
        <a href="./" class="header__logo-container">
          <div class="header__logo-img-cont">
            <img
              src={headshot}
              alt="Zach Asher Headshot"
              class="header__logo-img"
            />
          </div>
          <span class="header__logo-sub">Zach Asher</span>
        </a>
        <div class="header__main">
          <ul class="header__links">
            <li class="header__link-wrapper">
              <a href="./" class="header__link">
                {" "}
                Home{" "}
              </a>
            </li>
            <li class="header__link-wrapper">
              <a href="./#about" class="header__link">
                About{" "}
              </a>
            </li>
            <li class="header__link-wrapper">
              <a href="./#projects" class="header__link">
                Projects
              </a>
            </li>
            <li class="header__link-wrapper">
              <a href="./#contact" class="header__link">
                {" "}
                Contact{" "}
              </a>
            </li>
          </ul>
          <div class="header__main-ham-menu-cont" onClick={toggleMenu}>
            <img
              src={Hamburger}
              alt="hamburger menu"
              class={`header__main-ham-menu ${isMenuOpen ? 'd-none' : ''}`}
            />
            <img
              src={HamburgerClose}
              alt="hamburger menu close"
              class={`header__main-ham-menu-close ${isMenuOpen ? '' : 'd-none'}`}
            />
          </div>
        </div>
      </div>
      <div className={`header__sm-menu ${isMenuOpen ? 'header__sm-menu--active' : ''}`}>
        <div class="header__sm-menu-content">
          <ul class="header__sm-menu-links">
            <li className="header__sm-menu-link" onClick={closeMenu}>
              <a href="./"> Home </a>
            </li>

            <li className="header__sm-menu-link" onClick={closeMenu}>
              <a href="./#about"> About </a>
            </li>

            <li className="header__sm-menu-link" onClick={closeMenu}>
              <a href="./#projects"> Projects </a>
            </li>

            <li className="header__sm-menu-link" onClick={closeMenu}>
              <a href="./#contact"> Contact </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
