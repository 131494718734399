import React from "react";

import AspireImage from "../../assets/jpeg/Aspire.jpg";
import BrainFlixImage from "../../assets/jpeg/BrainFlix.jpg";
import BandSiteImage from "../../assets/jpeg/BandSite.jpg";
import CBSImage from "../../assets/jpeg/ConvertBankStatement.jpg";
import SarahAyling from "../../assets/jpeg/SarahAyling.jpg";
import ShrinkImage from "../../assets/jpeg/Shrink.jpg";

function Projects() {
  return (
    <section id="projects" class="projects sec-pad">
      <div class="main-container">
        <h2 class="heading heading-sec heading-sec__mb-bg">
          <span class="heading-sec__main">Projects</span>
          <span class="heading-sec__sub">
            Here are a few of the projects I've worked on so far.
          </span>
        </h2>
        <div class="projects__row">
          <div class="projects__row-img-cont">
            <img
              src={ShrinkImage}
              alt="Software Screenshot"
              class="projects__row-img"
              loading="lazy"
            />
          </div>
          <div class="projects__row-content">
            <h3 class="projects__row-content-title">Shrink.ai</h3>
            <p class="projects__row-content-desc">
              A SaaS application for generating text summaries of video files,
              integrating Supabase for user management and authentication, as
              well as Stripe for payments.
            </p>
            <a href="./Shrink.ai" class="btn btn--med btn--theme dynamicBgClr">
              Case Study
            </a>
          </div>
        </div>
        <div class="projects__row">
          <div class="projects__row-img-cont">
            <img
              src={CBSImage}
              alt="Software Screenshot"
              class="projects__row-img"
              loading="lazy"
            />
          </div>
          <div class="projects__row-content">
            <h3 class="projects__row-content-title">ConvertBankStatement</h3>
            <p class="projects__row-content-desc">
              A Saas application for converting PDF bank statements to to Excel
              (XLS) or CSV formats, integrating Supabase for user management and
              authentication.
            </p>
            <a
              href="./ConvertBankStatement"
              class="btn btn--med btn--theme dynamicBgClr"
            >
              Case Study
            </a>
          </div>
        </div>
        <div class="projects__row">
          <div class="projects__row-img-cont">
            <img
              src={SarahAyling}
              alt="Sarah Ayling Physiotherapy Mockup"
              class="projects__row-img"
              loading="lazy"
            />
          </div>
          <div class="projects__row-content">
            <h3 class="projects__row-content-title">
              Sarah Ayling Physiotherapy
            </h3>
            <p class="projects__row-content-desc">
              A sleek one-page website for a physiotherapy client, featuring
              easy appointment scheduling, essential information, and a contact
              form for seamless communication.
            </p>
            <a
              href="./SarahAylingPhysiotherapy"
              class="btn btn--med btn--theme dynamicBgClr"
            >
              Case Study
            </a>
          </div>
        </div>
        <div class="projects__content">
          <div class="projects__row">
            <div class="projects__row-img-cont">
              <img
                src={AspireImage}
                alt="Software Screenshot"
                class="projects__row-img"
                loading="lazy"
              />
            </div>
            <div class="projects__row-content">
              <h3 class="projects__row-content-title">Aspire Fitness</h3>
              <p class="projects__row-content-desc">
                A website for a gym, with a functional class management system,
                incorporating member and admin profiles, with different
                permissions.
              </p>
              <a
                href="./AspireFitness"
                class="btn btn--med btn--theme dynamicBgClr"
              >
                Case Study
              </a>
            </div>
          </div>
          <div class="projects__row">
            <div class="projects__row-img-cont">
              <img
                src={BrainFlixImage}
                alt="Software Screenshot"
                class="projects__row-img"
                loading="lazy"
              />
            </div>
            <div class="projects__row-content">
              <h3 class="projects__row-content-title">BrainFlix</h3>
              <p class="projects__row-content-desc">
                A YouTube clone, built using React on the front-end and Node for
                the back-end.
              </p>
              <a
                href="./BrainFlix"
                class="btn btn--med btn--theme dynamicBgClr"
              >
                Case Study
              </a>
            </div>
          </div>
          <div class="projects__row">
            <div class="projects__row-img-cont">
              <img
                src={BandSiteImage}
                alt="Software Screenshot"
                class="projects__row-img"
                loading="lazy"
              />
            </div>
            <div class="projects__row-content">
              <h3 class="projects__row-content-title">BandSite</h3>
              <p class="projects__row-content-desc">
                A responsive website for a mock band, with a functional comments
                section and shows page.
              </p>
              <a href="./BandSite" class="btn btn--med btn--theme dynamicBgClr">
                Case Study
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Projects;
