import React from "react";
import BrainFlixMockup from "../assets/jpeg/BrainFlix.jpg"

function BrainFlix() {
  return (
    <div>
      <section class="project-cs-hero">
        <div class="project-cs-hero__content">
          <h1 class="heading-primary">BrainFlix</h1>
          <div class="project-cs-hero__info">
            <p class="text-primary">
              A YouTube clone, built using React on the front-end and Node for
              the back-end.
            </p>
          </div>
          <div class="project-cs-hero__cta">
            <a
              href="https://64d954f2aee17357df8eff67--incredible-sfogliatella-d2e58a.netlify.app/"
              class="btn btn--bg"
              target="_blank"
              rel="noreferrer"
            >
              Live Link
            </a>
          </div>
        </div>
      </section>
      <section class="project-details">
        <div class="main-container">
          <div class="project-details__content">
            <div class="project-details__showcase-img-cont">
              <img
                src={BrainFlixMockup}
                alt="BrainFlix Mockup"
                class="project-details__showcase-img"
              />
            </div>
            <div class="project-details__content-main">
              <div class="project-details__desc">
                <h3 class="project-details__content-title">Project Overview</h3>
                <p class="project-details__desc-para">
                  This was my first project using React. As part of the
                  BrainStation Software Engineering bootcamp, we were tasked
                  with creating a YouTube clone called BrainFlix. We were
                  provided with a style guide for the visual design, but the
                  precise implementation was left to our creative discretion and
                  judgement.
                </p>
                <p class="project-details__desc-para">
                  We also created a back-end API using node.js which hosted all
                  the video data. The site dynically renders information based
                  on the currently selected video and removes it from the list
                  of upcoming videos. There was also an optional diving deeper
                  section, which I managed to complete in part. This included
                  having dynamic timestamps for the comments.
                </p>
              </div>
              <div class="project-details__tools-used">
                <h3 class="project-details__content-title">Tools Used</h3>
                <div class="skills">
                  <div class="skills__skill">HTML</div>
                  <div class="skills__skill">CSS</div>
                  <div class="skills__skill">JavaScript</div>
                  <div class="skills__skill">React</div>
                  <div class="skills__skill">SASS</div>
                  <div class="skills__skill">GIT</div>
                  <div class="skills__skill">Node</div>
                  <div class="skills__skill">Express</div>
                  <div class="skills__skill">JSON</div>
                </div>
              </div>
              <div class="project-details__links">
                <h3 class="project-details__content-title">See Live</h3>
                <a
                  href="https://64d954f2aee17357df8eff67--incredible-sfogliatella-d2e58a.netlify.app/"
                  class="btn btn--med btn--theme project-details__links-btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  Live Link
                </a>
                <a
                  href="https://github.com/zachasher/brainflix"
                  class="btn btn--med btn--theme-inv project-details__links-btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  Code Link
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BrainFlix;
