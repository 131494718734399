import React from "react";
import BandSiteMockup from "../assets/jpeg/BandSite.jpg";

function BandSite() {
  return (
    <div>
      <section class="project-cs-hero">
        <div class="project-cs-hero__content">
          <h1 class="heading-primary">BANDSITE</h1>
          <div class="project-cs-hero__info">
            <p class="text-primary">
              A responsive website for a mock band, with a functional comments
              section and shows page.
            </p>
          </div>
          <div class="project-cs-hero__cta">
            <a
              href="https://thriving-mochi-42f442.netlify.app/"
              class="btn btn--bg"
              target="_blank"
              rel="noreferrer"
            >
              Live Link
            </a>
          </div>
        </div>
      </section>
      <section class="project-details">
        <div class="main-container">
          <div class="project-details__content">
            <div class="project-details__showcase-img-cont">
              <img
                src={BandSiteMockup}
                alt="bandSite Mockup"
                class="project-details__showcase-img"
              />
            </div>
            <div class="project-details__content-main">
              <div class="project-details__desc">
                <h3 class="project-details__content-title">Project Overview</h3>
                <p class="project-details__desc-para">
                  This was one of the projects I worked on during my Software
                  Engineering bootcamp with BrainStation. We were tasked with
                  creating a responsive website for a mock band, that
                  incorporated a functional comments section and shows page.
                </p>
                <p class="project-details__desc-para">
                  We were provided with a style guide for the visual design, but
                  the precise implementation was left to our creative discretion
                  and judgement. There was also an optional diving deeper
                  section, which I managed to complete in full. This included
                  the ability to like and delete comments, as well as a number
                  of visual improvements to the site.
                </p>
              </div>
              <div class="project-details__tools-used">
                <h3 class="project-details__content-title">Tools Used</h3>
                <div class="skills">
                  <div class="skills__skill">HTML</div>
                  <div class="skills__skill">CSS</div>
                  <div class="skills__skill">JavaScript</div>
                  <div class="skills__skill">SASS</div>
                  <div class="skills__skill">GIT</div>
                </div>
              </div>
              <div class="project-details__links">
                <h3 class="project-details__content-title">See Live</h3>
                <a
                  href="https://thriving-mochi-42f442.netlify.app/"
                  class="btn btn--med btn--theme project-details__links-btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  Live Link
                </a>
                <a
                  href="https://github.com/zachasher/bandsite"
                  class="btn btn--med btn--theme-inv project-details__links-btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  Code Link
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BandSite;
