import React from "react";
import SarahAylingMockup from "../assets/jpeg/SarahAyling.jpg";

function SarahAyling() {
  return (
    <div>
      <section class="project-cs-hero">
        <div class="project-cs-hero__content">
          <h1 class="heading-primary">Sarah Ayling Physiotherapy</h1>
          <div class="project-cs-hero__info">
            <p class="text-primary">
              A sleek one-page website for a physiotherapy client, featuring
              easy appointment scheduling, essential information, and a contact
              form for seamless communication.
            </p>
          </div>
          <div class="project-cs-hero__cta">
            <a
              href="https://sarahayling.com/"
              class="btn btn--bg"
              target="_blank"
              rel="noreferrer"
            >
              Live Link
            </a>
          </div>
        </div>
      </section>
      <section class="project-details">
        <div class="main-container">
          <div class="project-details__content">
            <div class="project-details__showcase-img-cont">
              <img
                src={SarahAylingMockup}
                alt="bandSite Mockup"
                class="project-details__showcase-img"
              />
            </div>
            <div class="project-details__content-main">
              <div class="project-details__desc">
                <h3 class="project-details__content-title">Project Overview</h3>
                <p class="project-details__desc-para">
                  This is a sleek and responsive website I created for a
                  physiotherapy client, that includes a link to her booking
                  service, essential information about her services, as well as
                  a functional contact form for seamless communication.
                </p>
                <p class="project-details__desc-para">
                  I designed the website using HTML, CSS and BootStrap, aligning
                  the stying with her current branding. I then deployed on
                  Netlify via GitHub.
                </p>
              </div>
              <div class="project-details__tools-used">
                <h3 class="project-details__content-title">Tools Used</h3>
                <div class="skills">
                  <div class="skills__skill">HTML</div>
                  <div class="skills__skill">CSS</div>
                  <div class="skills__skill">JavaScript</div>
                  <div class="skills__skill">CSS</div>
                  <div class="skills__skill">BootStrap</div>
                  <div class="skills__skill">GIT</div>
                  <div class="skills__skill">GitHub</div>
                  <div class="skills__skill">Netlify</div>
                </div>
              </div>
              <div class="project-details__links">
                <h3 class="project-details__content-title">See Live</h3>
                <a
                  href="https://sarahayling.com/"
                  class="btn btn--med btn--theme project-details__links-btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  Live Link
                </a>
                <a
                  href="https://github.com/zachasher/sarahaylingphysio"
                  class="btn btn--med btn--theme-inv project-details__links-btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  Code Link
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SarahAyling;
