import React from "react";

function About() {
  return (
    <section id="about" class="about sec-pad">
      <div class="main-container">
        <h2 class="heading heading-sec heading-sec__mb-med">
          <span class="heading-sec__main">About Me</span>
          <span class="heading-sec__sub">
            From mechanical engineering to empowering lives as a personal
            trainer, my journey embodies adaptability, determination, and a
            passion for transformative growth.
          </span>
        </h2>
        <div class="about__content">
          <div class="about__content-main">
            <h3 class="about__content-title">Get to know me!</h3>
            <div class="about__content-details">
              <p class="about__content-details-para">
                I was born and raised in <strong>Zimbabwe</strong>, then moved
                to the <strong>UK</strong> for university, where I studied{" "}
                <strong>Mechanical Engineering.</strong> After university, I
                decided to pursue a career in the{" "}
                <strong>fitness industry</strong>, by becoming a self-employed
                personal trainer.
              </p>
              <p class="about__content-details-para">
                After 5 years of helping people get fit & healthy, I decided it
                was time for a new challenge, so I enrolled in a{" "}
                <strong>Software Engineering</strong> bootcamp with{" "}
                <strong>BrainStation.</strong> Through this, I developed a solid
                foundation in both front-end and back-end development.
              </p>
              <p class="about__content-details-para">
                I'm now a qualified freelance
                <strong> Full Stack Developer</strong>, looking to make a
                difference in this rapidly evolving field. I'm passionate about
                bringing ideas to life through code and am always eager to
                improve.
              </p>
            </div>
            <a href="./#contact" class="btn btn--med btn--theme dynamicBgClr">
              Contact
            </a>
          </div>
          <div class="about__content-skills">
            <h3 class="about__content-title">My Skills</h3>
            <div class="skills">
              <div class="skills__skill">HTML</div>
              <div class="skills__skill">CSS</div>
              <div class="skills__skill">SASS</div>
              <div class="skills__skill">Tailwind CSS</div>
              <div class="skills__skill">JavaScript</div>
              <div class="skills__skill">TypeScript</div>
              <div class="skills__skill">React.js</div>
              <div class="skills__skill">Next.js</div>
              <div class="skills__skill">Axios</div>
              <div class="skills__skill">GIT</div>
              <div class="skills__skill">GitHub</div>
              <div class="skills__skill">Node.js</div>
              <div class="skills__skill">Express</div>
              <div class="skills__skill">Knex</div>
              <div class="skills__skill">MySQL</div>
              <div class="skills__skill">Jest</div>
              <div class="skills__skill">Canva</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
