import React from "react";
import AspireImage from "../assets/jpeg/Aspire.jpg";


function AspireProject() {

  return (
    <div>
      <section class="project-cs-hero">
        <div class="project-cs-hero__content">
          <h1 class="heading-primary">Aspire Fitness</h1>
          <div class="project-cs-hero__info">
            <p class="text-primary">
              A website for a gym, with a functional class management system,
              incorporating member and admin profiles, with different
              permissions.
            </p>
          </div>
          <div class="project-cs-hero__cta">
            <a
              href="https://github.com/zachasher/capstone"
              class="btn btn--bg"
              target="_blank"
              rel="noreferrer"
            >
              Code Link
            </a>
          </div>
        </div>
      </section>
      <section class="project-details">
        <div class="main-container">
          <div class="project-details__content">
            <div class="project-details__showcase-img-cont">
              <img
                src={AspireImage}
                alt="Aspire Fitness Mockup"
                class="project-details__showcase-img"
              />
            </div>
            <div class="project-details__content-main">
              <div class="project-details__desc">
                <h3 class="project-details__content-title">Project Overview</h3>
                <p class="project-details__desc-para">
                  This was my final capstone project for my Software Engineering
                  bootcamp with BrainStation. We could design anything we
                  wanted, using any range of technologies we had learned.
                </p>
                <p class="project-details__desc-para">
                  I opted to build a website for a mock gym, with a functional
                  class management system, that incorporated member and admin
                  profiles with different permissions. Gym managers can add,
                  edit and delete classes from the main timetable. Members can
                  then add classes of their choice to their own personal
                  timetable.
                </p>
                <p class="project-details__desc-para">
                  This was brough to life using a wide range of front-end and
                  back-end technologies, resulting in my most complex project to
                  date. Unfortunately, due to the common practice of paying to
                  host a back-end and MySQL database, I am unable to provide a
                  live demo of the site. However, you can watch my project
                  presentation below and find the code on GitHub.
                </p>
                <br />
                <div
                  style={{position: 'relative',
                paddingBottom: '66.49282920469362%',
                height: '0'}}
                >
                  <iframe
                    title="Aspire Fitness Presentation"
                    src="https://www.loom.com/embed/45efaba23bd04d5dbaeb7aa5d7fdfc90?sid=9e1b84f7-9eae-4a0a-8fdd-5095062686a9"
                    frameborder="0"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowfullscreen
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                    }}
                  ></iframe>
                </div>
              </div>
              <div class="project-details__tools-used">
                <h3 class="project-details__content-title">Tools Used</h3>
                <div class="skills">
                  <div class="skills__skill">HTML</div>
                  <div class="skills__skill">CSS</div>
                  <div class="skills__skill">JavaScript</div>
                  <div class="skills__skill">React</div>
                  <div class="skills__skill">SASS</div>
                  <div class="skills__skill">GIT</div>
                  <div class="skills__skill">Node</div>
                  <div class="skills__skill">Express</div>
                  <div class="skills__skill">Knex</div>
                  <div class="skills__skill">JWT</div>
                  <div class="skills__skill">MySQL</div>
                </div>
              </div>
              <div class="project-details__links">
                <h3 class="project-details__content-title">See Live</h3>
                {/* <!-- <a
              href="#"
              class="btn btn--med btn--theme project-details__links-btn"
              target="_blank"
              >Live Link</a
            > --> */}
                <a
                  href="https://github.com/zachasher/capstone"
                  class="btn btn--med btn--theme-inv project-details__links-btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  Code Link
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AspireProject;
