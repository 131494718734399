import React from 'react'

function Contact() {
  return (
    <section id="contact" class="contact sec-pad dynamicBg">
    <div class="main-container">
      <h2 class="heading heading-sec heading-sec__mb-med">
        <span class="heading-sec__main heading-sec__main--lt">Contact</span>
        <span class="heading-sec__sub heading-sec__sub--lt">
          Please feel free to reach out if you have any questions or would
          like to work together.
        </span>
      </h2>
      <div class="contact__form-container">
        <form action="https://formsubmit.co/52625c62a9becea30f4d1a0e98d6d7f9" method="POST" class="contact__form">
          <input type="hidden" name="_subject" value="New website message!"/>
          <div class="contact__form-field">
            <label class="contact__form-label" for="name">Name</label>
            <input
              required
              placeholder="Enter Your Name"
              type="text"
              class="contact__form-input"
              name="name"
              id="name"
            />
          </div>
          <div class="contact__form-field">
            <label class="contact__form-label" for="email">Email</label>
            <input
              required
              placeholder="Enter Your Email Address"
              type="email"
              class="contact__form-input"
              name="email"
              id="email"
            />
          </div>
          <div class="contact__form-field">
            <label class="contact__form-label" for="message">Message</label>
            <textarea
              required
              cols="30"
              rows="10"
              class="contact__form-input"
              placeholder="Enter Your Message"
              name="message"
              id="message"
            ></textarea>
          </div>
          <button type="submit" class="btn btn--theme contact__btn">
            Submit
          </button>
        </form>
      </div>
    </div>
  </section>

  );
}

export default Contact