import React from "react";

import LinkedInIcon from "../../assets/png/linkedin-ico.png";
import GitHubIcon from "../../assets/png/github-ico.png";

function Footer() {
  return (
    <footer class="main-footer">
      <div class="main-container">
        <div class="main-footer__upper">
          <div class="main-footer__row main-footer__row-1">
            <h2 class="heading heading-sm main-footer__heading-sm">
              <span>Social</span>
            </h2>
            <div class="main-footer__social-cont">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/zachasher/"
              >
                <img
                  class="main-footer__icon"
                  src={LinkedInIcon}
                  alt="icon"
                />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://github.com/zachasher"
              >
                <img
                  class="main-footer__icon"
                  src={GitHubIcon}
                  alt="icon"
                />
              </a>
              {/* <!-- <a target="_blank" rel="noreferrer" href="#">
              <img
                class="main-footer__icon"
                src="./assets/png/twitter-ico.png"
                alt="icon"
              />
            </a>
            <a target="_blank" rel="noreferrer" href="#">
              <img
                class="main-footer__icon"
                src="./assets/png/yt-ico.png"
                alt="icon"
              />
            </a>
            <a target="_blank" rel="noreferrer" href="#">
              <img
                class="main-footer__icon main-footer__icon--mr-none"
                src="./assets/png/insta-ico.png"
                alt="icon"
              />
            </a> --> */}
            </div>
          </div>
          <div class="main-footer__row main-footer__row-2">
            <h4 class="heading heading-sm text-lt">Zach Asher</h4>
            <p class="main-footer__short-desc">
              Crafting clean code, aesthetic designs and innovative solutions as a dedicated
              software engineer, driven by a passion for turning ideas into
              reality.
            </p>
          </div>
        </div>

        {/* <!-- If you give me some credit or shoutout here by linking to my website, then it will be a big thing for me and will help me a lot :) --> */}
        <div class="main-footer__lower">
          &copy; Copyright 2024 - Zach Asher
          {/* <a rel="noreferrer" target="_blank" href="https://rammaheshwari.com">
            Ram Maheshwari
          </a> */}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
