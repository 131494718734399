import React from 'react'

import GithubIcon from "../../assets/png/github-ico.png";
import LinkedInIcon from "../../assets/png/linkedin-ico.png";

function Hero() {
  return (
    <section class="home-hero">
      <div class="home-hero__content">
        <h1 class="heading-primary">Hi, My name is Zach Asher</h1>
        <div class="home-hero__info">
          <p class="text-primary">
            As a dedicated software engineer, I'm driven by a passion for
            turning ideas into reality, through clean code, aesthetic designs and innovative
            solutions.
          </p>
        </div>
        <div class="home-hero__cta">
          <a href="./#projects" class="btn btn--bg">Projects</a>
        </div>
      </div>
      <div class="home-hero__socials">
        <div class="home-hero__social">
          <a
            href="https://www.linkedin.com/in/zachasher/"
            target="_blank"
            rel="noreferrer"
            class="home-hero__social-icon-link"
          >
            <img
              src={LinkedInIcon}
              alt="icon"
              class="home-hero__social-icon"
            />
          </a>
        </div>
        <div class="home-hero__social">
          <a
            href="https://github.com/zachasher"
            target="_blank"
            rel="noreferrer"
            class="home-hero__social-icon-link"
          >
            <img
              src={GithubIcon}
              alt="icon"
              class="home-hero__social-icon"
            />
          </a>
        </div>
        {/* <div class="home-hero__social">
          <a href="#" class="home-hero__social-icon-link">
            <img
              src="./assets/png/twitter-ico.png"
              alt="icon"
              class="home-hero__social-icon"
            />
          </a>
        </div>
        <div class="home-hero__social">
          <a href="#" class="home-hero__social-icon-link">
            <img
              src="./assets/png/yt-ico.png"
              alt="icon"
              class="home-hero__social-icon"
            />
          </a>
        </div>
        <div class="home-hero__social">
          <a
            href="#"
            class="home-hero__social-icon-link home-hero__social-icon-link--bd-none"
          >
            <img
              src="./assets/png/insta-ico.png"
              alt="icon"
              class="home-hero__social-icon"
            />
          </a>
        </div> */}
      </div>
      <div class="home-hero__mouse-scroll-cont">
        <div class="mouse"></div>
      </div>
    </section>
  )
}

export default Hero