import React from "react";
import CBSImage from "../assets/jpeg/ConvertBankStatement.jpg";

function ConvertBankStatement() {
  return (
    <div>
      <section class="project-cs-hero">
        <div class="project-cs-hero__content">
          <h1 class="heading-primary">Convert Bank Statement</h1>
          <div class="project-cs-hero__info">
            <p class="text-primary">
              A Saas application for converting PDF bank statements to to Excel
              (XLS) or CSV formats, integrating Supabase for user management and
              authentication.
            </p>
          </div>
          <div class="project-cs-hero__cta">
            <a
              href="https://www.convertbankstatement.io/"
              class="btn btn--bg"
              target="_blank"
              rel="noreferrer"
            >
              Live Link
            </a>
          </div>
        </div>
      </section>
      <section class="project-details">
        <div class="main-container">
          <div class="project-details__content">
            <div class="project-details__showcase-img-cont">
              <img
                src={CBSImage}
                alt="Convert Bank Statement Mockup"
                class="project-details__showcase-img"
              />
            </div>
            <div class="project-details__content-main">
              <div class="project-details__desc">
                <h3 class="project-details__content-title">Project Overview</h3>
                <p class="project-details__desc-para">
                  I worked in collaboration with another software developer and
                  designer to create a Saas application for coverting PDF bank
                  statements to Excel (XLS) or CSV formats.
                </p>
                <p class="project-details__desc-para">
                  I was mainly responsible for creating the front-end with
                  Next.js, using TypeScript and Tailwind CSS for styling. I also
                  integrated the front-end with Supabase for user management and
                  authentication.
                </p>
                <p class="project-details__desc-para">
                  We used a starter kit from Vercel which also integrated with
                  Stripe Checkout and the Stripe customer portal for payments
                  and subscriptions. The project was deployed on Vercel using
                  CI/CD through GitHub.
                </p>
              </div>
              <div class="project-details__tools-used">
                <h3 class="project-details__content-title">Tools Used</h3>
                <div class="skills">
                  <div class="skills__skill">Next.js</div>
                  <div class="skills__skill">Tailwind CSS</div>
                  <div class="skills__skill">TypeScript</div>
                  <div class="skills__skill">HTML</div>
                  <div class="skills__skill">CSS</div>
                  <div class="skills__skill">React</div>
                  <div class="skills__skill">GIT</div>
                  <div class="skills__skill">GitHub</div>
                  <div class="skills__skill">Vercel</div>
                  <div class="skills__skill">Supabase</div>
                  <div class="skills__skill">Stripe</div>
                </div>
              </div>
              <div class="project-details__links">
                <h3 class="project-details__content-title">See Live</h3>
                <a
                  href="https://www.convertbankstatement.io/"
                  class="btn btn--med btn--theme project-details__links-btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  Live Link
                </a>
                <a
                  href="https://github.com/zachasher/convertbankstatement"
                  class="btn btn--med btn--theme-inv project-details__links-btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  Code Link
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ConvertBankStatement;
